body {
  background-color: #ffc8b0;
  margin: 0 0;
}

@font-face {
  font-family: "Olopus";
  src: url("fonts/Olopus-gp3p.ttf") format("truetype"),
    url("fonts/Olopus-gp3p.woff") format("woff");
}
